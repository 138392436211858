import React from "react";
import { Container, ThemeProvider, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import theme from "../theme";
import MediaCard from "../components/MediaCard";
import cutlerypots from "../../img/categories/cutlery_pots.jpg";
import fashion from "../../img/categories/fashion.jpeg";
import footballplanter from "../../img/categories/football_planter.jpg";
import windmill from "../../img/categories/green.jpeg";
import ideas from "../../img/categories/ideas.jpeg";
import palletsofa from "../../img/categories/pallet_sofa.jpeg";
import penciltin from "../../img/categories/pencil_tins.jpeg";
import recycling from "../../img/categories/recycling.jpeg";
import reuse from "../../img/categories/reuse.jpeg";

const styles = (theme) => ({
  root: {},
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 100,
    },
    "&:hover": {
      zIndex: 1,
    },
    "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    },
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
});

function Categories(props) {
  const { classes } = props;

  const images = [
    {
      url: footballplanter,
      title: "Upcycle",
      link: "/contact",
    },
    {
      url: palletsofa,
      title: "Garden",
      link: "/contact",
    },
    {
      url: ideas,
      title: "Ideas",
      link: "/contact",
    },
    {
      url: penciltin,
      title: "Office",
      link: "/contact",
    },
    {
      url: recycling,
      title: "Recycle",
      link: "/contact",
    },
    {
      url: fashion,
      title: "Fashion",
      link: "/contact",
    },
    {
      url: reuse,
      title: "Reuse",
      link: "/contact",
    },
    {
      url: cutlerypots,
      title: "Kitchen",
      link: "/contact",
    },
    {
      url: windmill,
      title: "Green",
      link: "/contact",
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "#F5F5F5",
        padding: "3em",
      }}
    >
      <ThemeProvider theme={theme}>
        <Container component="section">
          <Typography
            marked="center"
            align="center"
            fontFamily={"Lato"}
            fontWeight={400}
            fontSize="36px"
            marginBottom={"0.5em"}
          >
            <strong> FOR RECYCLING AND UPCYCLING</strong>

            <div
              style={{
                height: 4,
                width: 60,
                background: theme.palette.primary.main,
                position: "absolute",
                left: "calc(50% - 30px)",
                transition: theme.transitions.create("opacity"),
                marginTop: "1%",
              }}
            />
          </Typography>
          <div className={classes.images}>
            {images.map((image, index) => (
              <MediaCard data={image} key={index} size={200} />
            ))}
          </div>
        </Container>
      </ThemeProvider>
    </Box>
  );
}

Categories.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Categories);
