import React from "react";
import { Container, ThemeProvider, Typography } from "@mui/material";
import { Box, Grid } from "@mui/material";
import CompanyName from "../utils/CompanyName";

import { withStyles } from "@mui/styles";
import themeTerms from "../themeTerms";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    display: "flex",
    position: "relative",
  },

  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  sideimg: {
    width: "80%",
    height: "80%",
    paddingLeft: 70,
    paddingTop: 40,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  h6: {
    marginTop: theme.spacing(2),
  },
  para: {
    fontSize: "20px",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    textAlign: "justify",
    display: "block",
  },
});

function AboutDescription(props) {
  const theme = themeTerms;

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Container
          sx={{
            marginTop: theme.spacing(7),
            marginBottom: theme.spacing(1),
            display: "flex",
          }}
        >
          <Grid container spacing={5} direction="column" justifyContent="center" alignItems="center">
            <Grid item xs="auto">
              <Typography variant="h5">
                <CompanyName />
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="body2">
                Welcome to <CompanyName />, Where Sustainability Meets Creativity! At <CompanyName />, we believe in the
                transformative power of recycling and upcycling – turning discarded items into treasures and
                contributing to a greener, more sustainable future. Our passion for environmental stewardship, coupled
                with a love for creativity, forms the foundation of this platform. Our Mission: Sustainability at the
                Heart: We are on a mission to promote sustainable living practices by showcasing the beauty and
                functionality of recycled and upcycled creations. Through informative content and inspiring projects, we
                aim to demonstrate that every small effort contributes to a significant positive impact on our planet.
                Celebrating Creativity: Beyond the environmental benefits, we celebrate the artistry that comes with
                transforming the old into something new. Our platform is a haven for individuals who appreciate the
                beauty of creativity and innovation, proving that eco-friendly choices can be stylish and imaginative.
                What Sets Us Apart: Inspiring Ideas: Whether you're a seasoned upcycler or just dipping your toes into
                the world of recycling, <CompanyName /> is your go-to resource for inspiring ideas. From DIY projects to
                in-depth guides, we provide a wealth of information to spark your creativity and ignite a passion for
                sustainable living. Educational Content: Understanding the environmental impact of our choices is
                crucial. Our blog posts delve into the intricacies of recycling processes, the benefits of upcycling,
                and the importance of responsible waste management. Knowledge empowers change, and we're here to empower
                you. Community Building: <CompanyName /> is more than just a platform; it's a community of like-minded
                individuals who share a commitment to sustainable living. Join us in discussions, share your upcycling
                successes, and be part of a movement that prioritizes the well-being of our planet.
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="body2">
                We believe that change starts at the individual level, and every action, no matter how small,
                contributes to a collective movement. Explore our website, try out our upcycling projects, and share
                your own creations with our community. Together, we can turn the tide towards a more sustainable and
                beautiful world.
              </Typography>
            </Grid>

            <Grid item xs="auto">
              <Typography variant="body1">
                Thank you for joining us on this journey of innovation, creativity, and conscious living. Let's redefine
                waste, one upcycled project at a time!
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default withStyles(styles)(AboutDescription);
