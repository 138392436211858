import React from "react";
import { Container, ThemeProvider, Typography, Box } from "@mui/material";
import theme from "../theme";

export default function AppSubtitle(props) {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          backgroundColor: theme.palette.primary.dark,
        }}
      >
        <Container
          sx={{
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography variant="subtitle1" fontFamily={"Lato"} fontWeight={300} fontSize="20px">
            <strong> The place for upcycling tips and tricks!</strong>
          </Typography>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
