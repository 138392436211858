import React from "react";
import { Container, ThemeProvider, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import CompanyName from "../utils/CompanyName";
import CompanyWebsite from "../utils/CompanyWebsite";
import themeTerms from "../themeTerms";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: "flex",
    position: "relative",
    flexDirection: "column",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  h6: {
    marginTop: theme.spacing(2),
  },
  para: {
    fontSize: "14px",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    textAlign: "justify",
    display: "block",
  },
});

function Privacy(props) {
  const { classes } = props;
  const theme = themeTerms;

  return (
    <ThemeProvider theme={theme}>
      <Container className={classes.container}>
        <Typography variant="h3" gutterBottom marked="center" align="center">
          Privacy
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          About privacy
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          <CompanyWebsite /> is committed to protecting and respecting your privacy. This Privacy Policy sets out how we
          collect and process personal information about you when you visit the website <CompanyWebsite />
          .co.uk, when you use our products and services (our “Services”), or when you otherwise do business or make
          contact with us. Please read this policy carefully to understand how we handle and treat your personal
          information.'
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          What information do we collect?
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          We may collect and process the following personal information from you:
        </Typography>

        <ul>
          <li>
            Information you provide to us: We collect personal information when you voluntarily provide us with such
            information in the course of using our website or Services. For example, when you register to use our
            Services, we will collect your name, email address and organization information. We also collect personal
            information from you when you subscribe to our newsletter, or respond to a survey. If you make an enquiry
            through our website, or contact us in any other way, we will keep a copy of your communications with us.
          </li>

          <li>
            Information we collect when you do business with us: We may process your personal information when you do
            business with us – for example, as a customer or prospective customer, or as a vendor, supplier, consultant
            or other third party. For example, we may hold your business contact information and financial account
            information (if any) and other communications you have with us for the purposes of maintaining our business
            relations with you.
          </li>

          <li>
            Information we automatically collect: We may also collect certain technical information by automatic means
            when you visit our website, such as IP address, browser type and operating system, referring URLs, your use
            of our website, and other clickstream data. We collect this information automatically through the use of
            various technologies, such as cookies.
          </li>

          <li>
            Personal information where we act as a data processor: We also process personal information on behalf of our
            customers in the context of supporting our products and services. Where a customer subscribes to our
            Services for their website, game or app, they will be the ones who control what event data is collected and
            stored on our systems. For example, they may ask us to log basic user data (e.g. email address or username),
            device identifiers, IP addresses, event type, and related source code. In such cases, we are data processors
            acting in accordance with the instructions of our customers. You will need to refer to the privacy policies
            of our customers to find out more about how such information is handled by them.
          </li>
        </ul>

        <Typography variant="h6" className={classes.h6}>
          What do we use your information for?
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          The personal information we collect from you may be used in one of the following ways:
        </Typography>

        <ul>
          <li>To deal with your inquiries and requests</li>
          <li>To create and administer records about any online account that you register with us</li>
          <li>To provide you with information and access to resources that you have requested from us</li>
          <li>
            To provide you with technical support (your information helps us to better respond to your individual needs)
          </li>
          <li>
            To improve our website (we continually strive to improve our website offerings based on the information and
            feedback we receive from you), including to improve the navigation and content of our sites
          </li>
          <li>For website and system administration and security</li>
          <li>
            For general business purposes, including to improve customer service (your information helps us to more
            effectively respond to your customer service requests and support needs), to help us improve the content and
            functionality of our Services, to better understand our users, to protect against wrongdoing, to enforce our
            Terms of Service, and to generally manage our business
          </li>
          <li>To process transactions and to provide Services to our customers and end-users</li>
          <li>For recruitment purposes, where you apply for a job with us</li>
          <li>To administer a contest, promotion, survey, or other site features</li>
        </ul>

        <Typography variant="h6" className={classes.h6}>
          How do we protect your information?
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          We implement a variety of security measures to maintain the safety of your personal information when you
          enter, submit, or access your personal information. We offer the use of a secure server. All supplied
          sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into
          our database only to be accessible by those authorised with special access rights to such systems, and are
          required to keep the information confidential.
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          Do we use cookies?
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          Yes. Cookies are small files that a site or its service provider transfers to your computers hard drive
          through your Web browser (if you allow) that enables the sites or service providers systems to recognize your
          browser and capture and remember certain information.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          We use cookies to understand and save your preferences for future visits, to advertise to you on other sites
          and compile aggregate data about site traffic and site interaction so that we can offer better site
          experiences and tools in the future.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          You may refuse to accept cookies by activating the setting on your browser which allows you to refuse the
          setting of cookies. However, if you choose to disable cookies, you may be unable to access certain parts of
          our site. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue
          cookies when you log on to our site.
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          GDPR Privacy Policy (Data Protection Rights){" "}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled
          to the following:
        </Typography>
        <ul>
          <li>
            The right to access – You have the right to request copies of your personal data. We may charge you a small
            fee for this service.
          </li>
          <li>
            The right to rectification – You have the right to request that we correct any information you believe is
            inaccurate. You also have the right to request that we complete the information you believe is incomplete.
          </li>
          <li>
            The right to erasure – You have the right to request that we erase your personal data, under certain
            conditions.
          </li>
          <li>
            The right to restrict processing – You have the right to request that we restrict the processing of your
            personal data, under certain conditions.
          </li>
          <li>
            The right to object to processing – You have the right to object to our processing of your personal data,
            under certain conditions.
          </li>
          <li>
            The right to data portability – You have the right to request that we transfer the data that we have
            collected to another organization, or directly to you, under certain conditions.
          </li>
          <li>
            If you make a request, we have one month to respond to you. If you would like to exercise any of these
            rights, please contact us.
          </li>
          <li>
            Another part of our priority is adding protection for children while using the internet. We encourage
            parents and guardians to observe, participate in, and/or monitor and guide their online activity.
            <CompanyName /> does not knowingly collect any Personal Identifiable Information from children under the age
            of 13. If you think that your child provided this kind of information on our website, we strongly encourage
            you to contact us immediately and we will do our best efforts to promptly remove such information from our
            records.
          </li>
        </ul>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          We do not sell or trade your personal information to third parties.
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          Third Party Links
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          Occasionally, at our discretion, we may include or offer third party products or services on our website. If
          you access other websites using the links provided, the operators of these websites may collect information
          from you that will be used by them in accordance with their privacy policies. These third party sites have
          separate and independent privacy policies. We therefore have no responsibility or liability for the content
          and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome
          any feedback about these sites.
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          Log Files{" "}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          <CompanyName /> follows a standard procedure of using log files. These files log visitors when they visit
          websites. All hosting companies do this and a part of hosting services' analytics. The information collected
          by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and
          time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information
          that is personally identifiable. The purpose of the information is for analyzing trends, administering the
          site, tracking users' movement on the website, and gathering demographic information.
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          Your Rights
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          If you are from the EU, you may have the right to access a copy of the personal information we hold about you,
          or to request the correction, amendment or deletion of such information where it is inaccurate or processed.
          To make such a request, please contact us using the contact form. We will consider and respond to your request
          in accordance with applicable laws.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          Furthermore, we commit to giving you an opportunity to opt-out if your personal information is to be disclosed
          to any other independent third parties, or to be used for a purpose materially different from those that are
          set out in this Privacy Policy. Where sensitive personal information is involved, we will always obtain your
          express opt-in consent to do such things. If you otherwise wish to limit the use or disclosure of your
          personal information, please write to us at the contact details further below.
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          Data Retention
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          We may retain your personal information as long as you continue to use the Services, have an account with us
          or for as long as is necessary to fulfil the purposes outlined in the policy. You can ask to close your
          account by contacting us at the details below and we will delete your personal information on request.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          We may however retain personal information for an additional period as is permitted or required under
          applicable laws, for legal, tax or regulatory reasons, or for legitimate and lawful business purposes.
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          Changes to our privacy policy
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          If we decide to change our privacy policy, we will post those changes on this page, and/or update the Privacy
          Policy modification date.
        </Typography>
      </Container>
    </ThemeProvider>
  );
}

export default withStyles(styles)(Privacy);
