import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Categories from "./modules/views/Categories";
import LandingPage from "./modules/views/LandingPage/LandingPage";
import ContactButton from "./modules/views/ContactButton";
import AppSubtitle from "./modules/views/AppSubtitle";
import Blog from "./modules/views/Blog";
import ScrollToTop from "./modules/utils/ScrollToTop";
import Contact from "./modules/views/contact/Contact";
import AppFooter from "./modules/views/AppFooter";
import AboutUs from "./modules/views/AboutUs";
import Privacy from "./modules/views/Privacy";
import Terms from "./modules/views/Terms";
import withRoot from "./modules/withRoot";
import TopNavBar from "./modules/views/TopNavBar";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route path="/about" element={<AboutView />} />
          <Route path="/contact" element={<ContactView />} />
          <Route path="/terms" element={<TermsView />} />
          <Route path="/privacy" element={<PrivacyView />} />
          <Route exact path="/" element={<Index />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

function Index() {
  return (
    <React.Fragment>
      <TopNavBar />
      <LandingPage />
      <AppSubtitle />
      <Categories />
      <Blog />
      <ContactButton />
      <AppFooter />
    </React.Fragment>
  );
}

function AboutView() {
  return (
    <React.Fragment>
      <TopNavBar />
      <AboutUs />
      <AppFooter />
    </React.Fragment>
  );
}

function PrivacyView() {
  return (
    <React.Fragment>
      <TopNavBar />
      <Privacy />
      <AppFooter />
    </React.Fragment>
  );
}

function TermsView() {
  return (
    <React.Fragment>
      <TopNavBar />
      <Terms />
      <AppFooter />
    </React.Fragment>
  );
}

function ContactView() {
  return (
    <React.Fragment>
      <TopNavBar />
      <Contact />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(App);
