import React from "react";
import { ThemeProvider, Box, Typography } from "@mui/material";
import theme from "../theme";
import BlogCard from "../utils/Blog/BlogCard";
import fashion from "../../img/categories/fashion.jpeg";
import footballplanter from "../../img/categories/football_planter.jpg";
import reuse from "../../img/categories/reuse.jpeg";
import palletsofa from "../../img/categories/pallet_sofa.jpeg";
import recycling from "../../img/categories/recycling.jpeg";
import cutlerypots from "../../img/categories/cutlery_pots.jpg";

const blogs = [
  {
    title: "10 Creative Upcycling Projects",
    text: "In a world where the mantra is reduce, reuse, recycle, upcycling emerges as a delightful way to give old items a new lease on life.  If you're itching for a creative outlet and want to inject some personality into your surroundings, look no further.",
    image: cutlerypots,
    link: "/contact",
  },
  {
    title: "The Environmental Impact of Recycling",
    text: "In our quest for a more sustainable and eco-friendly world, recycling stands as a beacon of hope. It's more than just tossing items into the blue bin; it's a collective effort that reverberates far beyond our individual actions. Join us as we unravel the layers of recycling and delve into the profound environmental impact it holds.",
    image: recycling,
    link: "/contact",
  },
  {
    title: "Upcycled Fashion",
    text: "In a world where fashion is ever-evolving, the term upcycled has seamlessly woven its way into the fabric of sustainable style. Upcycled fashion is not just a trend; it's a movement that challenges the conventional norms of the industry. Join us as we explore the art, creativity, and positive impact behind the upcycled fashion revolution.",
    image: fashion,
    link: "/contact",
  },
  {
    title: "From Trash to Treasure",
    text: "In a world that often discards the old in pursuit of the new, the art of upcycling emerges as a beacon of creativity and sustainability. From Trash to Treasure is not just a phrase; it's a philosophy that transforms discarded items into valuable, purposeful, and aesthetically pleasing treasures.",
    image: reuse,
    link: "/contact",
  },
  {
    title: "The Art of Dumpster Diving",
    text: "In a world dominated by consumerism and disposability, a counterculture has emerged that challenges our perceptions of waste. Dumpster diving – a term once associated with scavenging – has transformed into an art form, unveiling hidden treasures amidst discarded items.",
    image: palletsofa,
    link: "/contact",
  },
  {
    title: "Upcycled Garden",
    text: "Gardening enthusiasts and eco-conscious individuals are increasingly turning to a green revolution that extends beyond the plants themselves. The concept of an upcycled garden marries the principles of sustainability with the joy of nurturing green spaces. . In this blog post, we'll explore the fascinating world of upcycled gardens.",
    image: footballplanter,
    link: "/contact",
  },
];

export default function Blog(props) {
  return (
    <Box
      sx={{
        padding: "3em",
        display: "flex",
        width: "100%",
        backgroundColor: theme.palette.primary.dark,
        flexDirection: "column",
      }}
    >
      <ThemeProvider theme={theme}>
        <Box>
          <Typography
            marked="center"
            align="center"
            fontFamily={"Lato"}
            fontWeight={400}
            fontSize="48px"
            marginBottom={"0.5em"}
          >
            Blog Posts
            <div
              style={{
                height: 4,
                width: 60,
                background: "white",
                position: "absolute",
                left: "calc(50% - 30px)",
                transition: theme.transitions.create("opacity"),
                marginTop: "1%",
              }}
            />
          </Typography>
        </Box>
        <Box>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "1em",
              marginTop: theme.spacing(8),
            }}
          >
            {blogs.map((blog, index) => (
              <BlogCard data={blog} key={index} size={200} />
            ))}
          </div>
        </Box>
      </ThemeProvider>
    </Box>
  );
}
