import React from "react";
import { Container, ThemeProvider, Typography } from "@mui/material";
import { Box } from "@mui/material";

import { withStyles } from "@mui/styles";
import theme from "../theme";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: "flex",
    position: "relative",
  },

  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  sideimg: {
    width: "80%",
    height: "80%",
    paddingLeft: 70,
    paddingTop: 40,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  h6: {
    marginTop: theme.spacing(2),
  },
  para: {
    fontSize: "20px",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    textAlign: "justify",
    display: "block",
  },
});

function About(props) {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          backgroundColor: theme.palette.primary.dark,
        }}
      >
        <Container
          sx={{
            marginTop: theme.spacing(7),
            marginBottom: theme.spacing(4),
          }}
        >
          <Box>
            <Typography
              gutterBottom
              marked="center"
              align="center"
              fontFamily={"Lato"}
              fontWeight={400}
              fontSize="48px"
            >
              About
              <div
                style={{
                  height: 4,
                  width: 50,
                  background: "white",
                  position: "absolute",
                  left: "calc(50% - 30px)",
                  transition: theme.transitions.create("opacity"),
                }}
              />
            </Typography>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default withStyles(styles)(About);
