import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Container, ThemeProvider, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";

import CompanyWebsite from "../utils/CompanyWebsite";
import themeTerms from "../themeTerms";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: "flex",
    position: "relative",
    flexDirection: "column",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  h6: {
    marginTop: theme.spacing(2),
  },
  para: {
    fontSize: "14px",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    textAlign: "justify",
    display: "block",
  },
});

function Terms(props) {
  const { classes } = props;
  const theme = themeTerms;
  const [content, setContent] = useState([]);
  const loadContent = async () => {
    const response = await fetch("apiv2/content/1/");
    const data = await response.json();
    setContent(data);
  };
  useEffect(() => {
    loadContent();
  }, []);

  return content ? (
    <ThemeProvider theme={theme}>
      <Container className={classes.container}>
        <Typography variant="h3" gutterBottom marked="center" align="center">
          Terms
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          1. Services
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          1.1 These <CompanyWebsite /> Terms of Service (these "Terms") apply to the features and functions provided by
          <CompanyWebsite /> ("our," or "we") via <CompanyWebsite /> (the "Site") (collectively, the "Services"). By
          accessing or using the Site or the Services, you agree to be bound by these Terms. If you do not agree to
          these Terms, you are not allowed to use the Site or the Services. The "Effective Date" of these Terms is the
          date you first use the Site, or access any of the Services.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          1.2 If you are using the Site or accessing the Services in your capacity as an employee, consultant or agent
          of a company or other entity, you represent that you are an employee, consultant or agent of that company or
          entity, and that you have the authority to bind that company or entity to these Terms. For the purpose of
          these Terms, you (and, if applicable, the company or entity that you represent) will be referred to as
          "Customer" or "you".
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          1.3 <CompanyWebsite /> reserves the right to change or modify these Terms, or any of our other policies or
          guidelines, at any time upon notice to you. We may provide that notice in a variety of ways, including,
          without limitation, sending you an email, posting a notice on the Site, or posting the revised Terms on the
          Site and revising the date at the top of these Terms. Any changes or modifications will be effective after we
          provide notice that these Terms have been modified. You acknowledge that your continued use of the Site or any
          of the Services following such notice constitutes your acceptance of the modified Terms.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          1.4 <CompanyWebsite /> reserves the right – at any time, and without notice or liability to you – to modify
          the Site or the Services, or any part of them, temporarily or permanently. We may modify the Services for a
          variety of reasons, including, without limitation, for the purpose of providing new features, implementing new
          protocols, maintaining compatibility with emerging standards, or complying with regulatory requirements.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          1.5 These Terms form a binding agreement between you and <CompanyWebsite /> . Violation of any of the Terms
          below may result in the termination of your account(s).
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          2. Privacy
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          2.1 Please see <CompanyWebsite />’ privacy policy at <CompanyWebsite />
          /privacy for information about how we collect, use, and disclose information about users of the Site and the
          Services. By using the Site and the Services, you consent to our collection, use, and disclosure of
          information as set forth in our privacy policy, as we may update that policy from time to time.
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          3. Access to services
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          3.1 Subject to your continued compliance with these Terms, <CompanyWebsite />
          grants you a limited, non-transferable, non-exclusive, revocable right and license to access and use the
          Services and its associated documentation, solely for your own purposes. You understand that{" "}
          <CompanyWebsite /> uses third-party vendors and hosting partners to provide the necessary hardware, software,
          networking, storage, and related technology required to provide the Services, and you agree that{" "}
          <CompanyWebsite /> is not and will not be liable or responsible for the acts or omissions of such third-party
          vendors or hosting partners.
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          4. Restrictions
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          44.1 Except as expressly authorized by these Terms, you may not: (a) modify, disclose, alter, translate or
          create derivative works of the Site or the Services; (b) license, sublicense, resell, distribute, lease, rent,
          lend, transfer, assign or otherwise dispose of the Services or any Report (or any components thereof); (c)
          offer any part of the Services (including, without limitation, any Report) on a timeshare or service bureau
          basis; (c) allow or permit any third party to access or use the Services; (d) use the Site or the Services to
          store or transmit any viruses, software routines, or other code designed to permit anyone to access in an
          unauthorized manner, disable, erase or otherwise harm software, hardware, or data, or to perform any other
          harmful actions; (e) build a competitive product or service, or copy any features or functions of the Site or
          the Services (including, without limitation, the look-and-feel of the Site or the Services); (f) interfere
          with or disrupt the integrity or performance of the Site or the Services;&nbsp;(g) disclose to any third party
          any performance information or analysis relating to the Site or the Services; (h) remove, alter or obscure any
          proprietary notices in or on the Site or the Services, including copyright notices; (i) use the Site or the
          Services or any product thereof for any illegal or unauthorized purpose, or in a manner which violates any
          laws or regulations in your jurisdiction; (j) reverse engineer, decompile, disassemble, or otherwise attempt
          to discover the source code, object code, or underlying structure, ideas, or algorithms that make up the
          Services or any software, documentation, or data relating to the Services, except to the limited extent that
          applicable law prohibits such a restriction; or (k) cause or permit any third party to do any of the
          foregoing.
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          5. Content
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          5.1 If you publish or upload data, images, code or content, or otherwise make (or allow any third party to
          make) material available by means of the Site or the Services (collectively, "Content"), you agree that you
          are entirely responsible for such Content, and for any harm or liability resulting from or arising out of that
          Content. Your responsibility applies whether or not the Content in question constitutes text, graphics, audio
          files, video files, computer software, or any other type of content, and whether or not you were the original
          creator or owner of the Content. You agree that you will be responsible for all Content on your account(s),
          even if placed there by third parties. By publishing or uploading Content to the Site or the Services, you
          represent and warrant that:
        </Typography>

        <ul>
          <li>
            the Content does not and will not infringe, violate or misappropriate the Intellectual Property Rights of
            any third party (where "Intellectual Property Rights" are defined as any patents, copyrights, moral rights,
            trademarks, trade secrets, or any other form of intellectual property rights recognized in any jurisdiction
            in the world, including applications and registrations for any of the foregoing);
          </li>

          <li>
            you have obtained all rights and permissions necessary to publish and/or use the Content in the manner in
            which you have published and/or used it;
          </li>
          <li>
            <CompanyWebsite />
            ’s use of the Content for the purpose of providing the Services (including, without limitation, downloading,
            copying, processing, or creating aggregations of the Content) does not and will not (i) violate any
            applicable laws or regulations, or (ii) infringe, violate, or misappropriate the Intellectual Property
            Rights of any third party;
          </li>

          <li>you have fully complied with any third-party licenses relating to the Content;</li>

          <li>
            the Content does not contain or install any viruses, worms, malware, Trojan horses or other harmful or
            destructive code;
          </li>

          <li>
            the Content does not and will not include any: (i) "personal health information", (ii) government issued
            identification numbers, including U.S. Social Security numbers, drivers’ licence numbers or other
            state-issued identification numbers; (iii) financial account information, including bank account numbers;
            (iv) payment card data, including credit card or debit card numbers; or (iv) "sensitive" personal data, as
            defined under Directive 95/46/EC of the European Parliament ("EU Directive") and any national laws adopted
            pursuant to the EU Directive, about residents of Switzerland and any member country of the European Union,
            including racial or ethnic origin, political opinions, religious beliefs, trade union membership, physical
            or mental health or condition, sexual life, or the commission or alleged commission any crime or offense;
          </li>

          <li>
            the Content is not spam, is not randomly-generated, and does not contain unethical or unwanted commercial
            content designed to drive traffic to third party sites or boost the search engine rankings of third party
            sites, or for any other unlawful acts (such as phishing), or for misleading recipients as to the source of
            the material (such as spoofing);
          </li>

          <li>
            the Content does not contain threats or incitement to violence, and does not violate the privacy or
            publicity rights of any third party;
          </li>

          <li>
            the Content is not being advertised via unwanted electronic messages (such as, by way of example, spam links
            on newsgroups, email lists, other blogs and web sites, and similar unsolicited promotional methods);
          </li>
          <li>
            the Content is not named in a manner that misleads (or could mislead) third parties into thinking that you
            are another person or company (by way of example, your Content’s URL or name should not be confusingly
            similar to the name of another person or entity); and{" "}
          </li>
          <li>
            you have, in the case of Content that includes computer code, accurately categorized and/or described the
            type, nature, uses and effects of the materials, whether requested to do so by the Services or otherwise.{" "}
          </li>
        </ul>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          5.2 By submitting or uploading Content to the Services, you grant <CompanyWebsite /> a worldwide,
          royalty-free, and non-exclusive license (i) to use, reproduce, modify, adapt and publish that Content solely
          for the purpose of providing the Services to you; and (ii) to create aggregations and summaries of the Content
          or portions thereof and to use, disclose, and distribute such aggregations publicly to any third party in
          support of our business (both during the period that these Terms are in effect, and thereafter), provided that
          such aggregations and summaries do not directly or indirectly identify you or your Content. If you delete
          Content,
          <CompanyWebsite /> will use reasonable efforts to remove it from the Services. You acknowledge, however, that
          cached copies or other references to the Content may still be available.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          5.3 Without limiting any of your representations or warranties with respect to the Content, <CompanyWebsite />{" "}
          has the right (but not the obligation) to reject or remove any Content, without liability or notice to you,
          that
          <CompanyWebsite /> believes, in <CompanyWebsite />’ sole discretion: (i) violates these Terms or any{" "}
          <CompanyWebsite />
          policy, (ii) violates or misappropriates the Intellectual Property Rights of any third party, or (iii) is in
          any way harmful or objectionable.
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          6. Disclaimer
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          6.1 You acknowledge that the site and the services are provided on an "as is", "as available" basis, without
          warranty of any kind, whether express or implied, and that your use of the site and the services is at your
          sole risk. <CompanyWebsite /> does not warrant: (i) that the site or the services will meet your specific
          requirements, (ii) that the site or the services will be uninterrupted, timely, secure, or error-free, (iii)
          that the results that may be obtained from the use of the services will be accurate or reliable, (iv) that the
          quality of any products, services, information, or other material that you purchase or obtain through the site
          or the services will meet your expectations, or (v) that any errors in the site or the services will be
          corrected. <CompanyWebsite /> specifically disclaims any implied warranties of merchantability, fitness for
          particular purpose, title, and non-infringement.
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          7. Ownership; Reservation of rights
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          7.1 As between the parties: (i) you own all right, title and interest in and to your Content; and (ii)
          <CompanyWebsite /> owns all right, title and interest in and to the Site and the Services, and all
          Intellectual Property Rights therein. The look and feel of the Site and the Services, including any custom
          graphics, button icons, and scripts are also the property of <CompanyWebsite />, and you may not copy,
          imitate, or use them, in whole or in part, without <CompanyWebsite />’ prior written consent.{" "}
          <CompanyWebsite /> reserves all rights not expressly granted to you in these Terms, and <CompanyWebsite />{" "}
          does not grant any licenses to you or to any other party under these Terms, whether by implication, estoppel
          or otherwise, except as expressly set forth herein.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          7.2 You acknowledge that any suggestions, comments, or other feedback that you provide to <CompanyWebsite />{" "}
          with respect to the Site, the Services, or any other <CompanyWebsite /> product or service (collectively,
          "Feedback") will be owned by <CompanyWebsite />, including all Intellectual Property Rights therein, and will
          be and become
          <CompanyWebsite />’ Confidential Information (as defined below). You acknowledge and agree that{" "}
          <CompanyWebsite />
          will be free to use, disclose, reproduce, license, and otherwise distribute and exploit the Feedback as
          <CompanyWebsite /> sees fit, without obligation or restriction of any kind. At our request and expense, you
          agree to execute documents or take such further actions as we may reasonably request to help us acquire,
          perfect, and maintain our rights in the Feedback.
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          8. Confidential information
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          8.1 For the purposes of these Terms, "Confidential Information" means any technical or business information
          disclosed by one party to the other that: (i) if disclosed in writing, is marked "confidential" or
          "proprietary" at the time of disclosure; (ii) if disclosed orally, is identified as confidential or
          proprietary at the time of such disclosure, and is summarized in a writing sent by the disclosing Party to the
          receiving Party within thirty (30) days of the disclosure. For the purposes of these Terms you agree that the
          Feedback, any Reports we provide to you, and any non-public elements of the Site or the Services (including,
          without limitation, the source code of any <CompanyWebsite />
          -proprietary software), will be deemed to be
          <CompanyWebsite />
          ’s Confidential Information, regardless of whether it is marked as such.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          8.2 Neither of us will use the other party’s Confidential Information, except as permitted by these Terms.
          Each of us agrees to maintain in confidence and protect the other party’s Confidential Information using at
          least the same degree of care as it uses for its own information of a similar nature, but in all events at
          least a reasonable degree of care. Each of us agrees to take all reasonable precautions to prevent any
          unauthorized disclosure of the other party’s Confidential Information, including, without limitation,
          disclosing Confidential Information only to its employees, independent contractors, consultants, and legal and
          financial advisors (collectively, “Representatives”): (i) with a need to know such information, (ii) who are
          parties to appropriate agreements sufficient to comply with this Section 13, and (iii) who are informed of the
          nondisclosure obligations imposed by this Section 13. Each party will be responsible for all acts and
          omissions of its Representatives. The foregoing obligations will not restrict either party from disclosing
          Confidential Information of the other party pursuant to the order or requirement of a court, administrative
          agency, or other governmental body, provided that the party required to make such a disclosure gives
          reasonable notice to the other party to enable them to contest such order or requirement.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          8.3 The restrictions set forth will not apply with respect to any Confidential Information that: (i) was or
          becomes publicly known through no fault of the receiving party; (ii) was rightfully known or becomes
          rightfully known to the receiving party without confidential or proprietary restriction from a source other
          than the disclosing party who has a right to disclose it; (iii) is approved by the disclosing party for
          disclosure without restriction in a written document which is signed by a duly authorized officer of such
          disclosing party; or (iv) the receiving party independently develops without access to or use of the other
          party’s Confidential Information.
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          9. Trademarks
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          9.1 You acknowledge and agree that any <CompanyWebsite /> names, trademarks, service marks, logos, trade
          dress, or other branding included on the Site or as part of the Services (collectively, the "Marks") are owned
          by
          <CompanyWebsite /> and may not be copied, imitated, or used (in whole or in part) without <CompanyWebsite />
          ’s prior written consent. All other trademarks, names, or logos referenced on the Site or the Services
          (collectively, "Third-Party Trademarks") are the property of their respective owners, and the use of such
          Third-Party Trademarks inure to the benefit of their respective owners. The use of such Third-Party Trademarks
          is intended to denote interoperability, and does not constitute an affiliation by <CompanyWebsite /> or its
          licensors with any company or an endorsement or approval by that company of <CompanyWebsite />, its licensors,
          or their respective products or services.
        </Typography>

        <Typography variant="h6" className={classes.h6}>
          10. Terminology
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          10.1 These Terms, together with any policies incorporated into these Terms by reference, are the complete and
          exclusive understanding of the parties with respect to <CompanyWebsite />
          ’s provision of, and your use of and access to, the Site and the Services, and supersede all previous or
          contemporaneous agreements or communications, whether written or oral, relating to the subject matter of these
          Terms (including, without limitation, prior versions of these Terms). Any terms or conditions that you send to{" "}
          <CompanyWebsite /> that are inconsistent with or in addition to these Terms are hereby rejected by{" "}
          <CompanyWebsite />, and will be deemed void and of no effect.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          10.2 You agree that <CompanyWebsite /> has the right to use your name and logo on the Site or other{" "}
          <CompanyWebsite />
          websites or marketing materials, for the purposes of identifying you as a <CompanyWebsite /> customer and
          describing your use of the Services. You also agree that <CompanyWebsite /> may (but is under no obligation
          to): (i) issue a press release identifying you as a <CompanyWebsite /> customer; (ii) inform other potential
          customers that you are a user of the Services; and (iii) identify you as a customer in other forms of
          publicity (including, without limitation, case studies, blog posts, and the like.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          10.3 You may not assign these Terms, in whole or in part, by operation of law or otherwise, without the prior
          written consent of <CompanyWebsite />, and any attempted transfer, assignment or delegation without such
          consent will be void and of no effect. <CompanyWebsite /> may freely transfer, assign or delegate these Terms,
          or its rights and duties under these Terms, without notice to you. Subject to the foregoing, these Terms will
          be binding upon and will inure to the benefit of the parties and their respective representatives, heirs,
          administrators, successors and permitted assigns.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          10.4 Except as expressly set forth in these Terms, the exercise by either party of any of its remedies will be
          without prejudice to its other remedies under these Terms or otherwise. The failure by a party to enforce any
          part of these Terms will not constitute a waiver of future enforcement of that or any other provision. Any
          waiver of any provision of these Terms will be effective only if in writing and signed by an authorized
          representative of the waiving party.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          10.5 You agree that any notice that <CompanyWebsite /> is required to provide pursuant to these Terms can be
          given electronically, which may include an email to the email address you provide to <CompanyWebsite /> as
          part of your Registration Data. These notices can be about a wide variety of things, including responding to
          your questions, requests for additional information, and legal notices. You agree that such electronic notices
          satisfy any legal requirement that such communications be in writing. An electronic notice will be deemed to
          have been received on the day the email is sent to you, provided that the email is the same as the email
          address you provided as part of your Registration Data.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          10.6 You acknowledge that you are responsible for complying with all applicable laws and regulations
          associated with your access and use of the Site and Services, including, without limitation, all applicable
          export control laws and regulations.{" "}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          10.7 If any portion of these Terms is held to be unenforceable or invalid, that portion will be enforced to
          the maximum extent possible, and all other provisions will remain in full force and effect.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          10.8 Except for payments due under these Terms, neither party will be responsible for any delay or failure to
          perform that is attributable in whole or in part to any cause beyond its reasonable control, including,
          without limitation, acts of God (fire, storm, floods, earthquakes, etc.); civil disturbances; disruption of
          telecommunications, power or other essential services; interruption or termination of service by any service
          providers used by <CompanyWebsite /> to host the Services or to link its servers to the Internet; labor
          disturbances; vandalism; cable cut; computer viruses or other similar occurrences; or any malicious or
          unlawful acts of any third party.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textAlign: "justify",
            display: "block",
          }}
        >
          10.9 We are each independent contractors with respect to the subject matter of these Terms. Nothing contained
          in these Terms will be deemed or construed in any manner whatsoever to create a partnership, joint venture,
          employment, agency, fiduciary, or other similar relationship between us, and neither of us can bind the other
          contractually.{" "}
        </Typography>

        {content.map((item) => (
          <Typography
            variant="body1"
            sx={{
              marginBottom: theme.spacing(2),
              marginTop: theme.spacing(1),
              textAlign: "justify",
              display: "block",
            }}
            key={item.element}
          >
            <ReactMarkdown>{item.text}</ReactMarkdown>
          </Typography>
        ))}

        {content.map((item) => (
          <Typography
            variant="body1"
            sx={{
              marginBottom: theme.spacing(2),
              marginTop: theme.spacing(1),
              textAlign: "justify",
              display: "block",
            }}
            key={item.element}
          >
            Last updated: {item.updated_at}
          </Typography>
        ))}
      </Container>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <Typography>Loading</Typography>
    </ThemeProvider>
  );
}

export default withStyles(styles)(Terms);
